@use 'global-styles/marble/base/base.scss';

* {
	box-sizing: border-box;

	* {
		box-sizing: inherit;
	}
}

:focus-visible { //default focus rules that may be over-written on a per-component basis
	background-color: var(--color-focus-state-background);
	color: var(--color-grey-800);
	outline-color: var(--color-focus-state-background);
	outline-offset: 0;
	outline-width: 4px;
}

body {
	background: var(--color-site-background);
	font-family: var(--sans-serif);
}

input,
button,
a {
	font-family: inherit;
}

//Prevent tracking pixels and other broken images from breaking layout.
img[height='0'][width='0'] {
	position: absolute;
}

input {
	accent-color: var(--color-link);
	border: 1px solid var(--color-white);
	border-radius: base.$radius-sharp;
	padding: 10px;
	transition: all 0.3s;

	&[type='email'],
	&[type='text'] {
		appearance: none;
	}

	/* stylelint-disable-next-line selector-no-qualifying-type */
	&.is-invalid {
		border-color: var(--color-grey-900);
		color: var(--color-met-red);
	}

	&:focus-visible {
		outline-color: var(--color-focus-state-outline);
	}

	&::placeholder {
		color: var(--color-text);
	}
}

select {
	font-family: var(--sans-serif);
}

a {
	color: var(--color-link);
	font-weight: 600;
	text-decoration: underline;
	text-underline-offset: 0.2em;
	touch-action: manipulation;
	transition: all 0.2s;

	&:visited,
	&:hover {
		color: var(--color-link);
	}

	&:hover,
	&:focus {
		text-decoration: none;
	}
}

p {
	margin-bottom: var(--spacing-paragraph);
	word-break: break-word;
	
	&:last-child {
		margin-bottom: 0;
	}
}

b,
strong {
	font-weight: 600;
}

section {
	scroll-margin-top: 80px;
	@media only screen and (min-width: base.$breakpoint-m) {
		scroll-margin-top: 20px;	
	}
}
