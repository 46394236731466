
:root {
	//Reds
	--color-red-600: #f4c3cc;
	--color-red-600-75: #f4c3ccbf;
	--color-met-red: #e4002b;
	--color-met-red-medium: #a6192e;
	--color-met-red-dark: #6e182e;
	
	// Greens
	--color-green-good-news-400: #006440;
	--color-green-600: #afe1a5;
	--color-green-600-75: #afe1a5bf;

	// Blues 
	--color-blue-900: #99b9e4;
	--color-blue-600: #c8d8eb;
	--color-blue-600-75: #c8d8ebbf;
	--color-blue-500: #b2e4e4;
	--color-blue-500-75: #b2e4e4bf;
	--color-blue-100: #001e50;
	--color-blue-bright: #0050bc;

	//Purples
	--color-purple: #5d1049; // primary
	--color-purple-600: #f2e7fe;

	//Greys
	--color-grey-900: #222;
	--color-grey-800: #484848;
	--color-grey-700: #6d6d6d;

	--color-grey-400: #b8b8b8;
	--color-grey-300: #d6d6d6;
	--color-grey-200: #e5e5e5;
	--color-grey-100: #f4f4f4;
	--color-grey-050: #fbfbfb;

	--color-white: #fff;

	//Oranges
	--color-orange-600: #f8e08e;
	--color-orange-600-75: #f8e08ebf;

	//Yellows
	--color-yellow-600: #f6f0a0;
	--color-yellow-600-75: #f6f0a0bf;

	//shadows/transparencies
	--color-white-opaque-70: rgba(255, 255, 255, 0.7);
	--color-white-opaque-90: rgba(255, 255, 255, 0.9);
	--color-white-opaque-85: rgba(255, 255, 255, 0.85);
	--color-white-opaque: rgba(255, 255, 255, 1);
	--color-card-shadow: rgba(0, 0, 06, 0.08);
	--color-active-shadow: rgba(0, 0, 06, 0.18);
	--color-black-opaque-50: rgba(0, 0, 0, 0.5);
	--color-black-transparency: rgba(0, 0, 0, 0.8);

	//Accordion on Welcome page
	--color-welcome-header: #d12d4a;
	--color-accordion-1: #c42c4e;
	--color-accordion-2: #ae2d51;
	--color-accordion-3: #861c48;
	--color-accordion-4: #5f2645;

}
