/* stylelint-disable selector-no-qualifying-type */
/* stylelint-disable max-nesting-depth */
@use 'global-styles/marble/base/base.scss';

$masthead-top-bar-height: 35px;
$masthead-bottom-bar-height: 45px;

.masthead-print {
	display: none;
}

.content-wrapper--masthead {
	box-sizing: border-box;
	max-width: 100%;
	min-height: var(--masthead-height);

	@media only screen and (min-width: base.$breakpoint-m) {
		margin-bottom: 40px;
	}

	* {
		box-sizing: border-box;
	}
}

.masthead {
	background-color: var(--color-masthead-bg);
	color: var(--color-masthead-text);
	display: flex;
	font-weight: 400;
	height: var(--masthead-height);
	margin: 0 auto;
	max-width: 100%;
	padding: 0;
	position: sticky;
	top: 0;
	width: 100%;
	z-index: base.z('global-nav');

	@media only screen and (min-width: base.$breakpoint-m) {
		position: relative;
	}

	a {
		color: var(--color-masthead-text);
		font-weight: 500;
		text-decoration: none;
		
		&:visited,
		&:focus,
		&:hover {
			color: var(--color-masthead-text);
			text-decoration: none;
		}
	}

	.icon {
		display: inline-block;
		fill: currentColor;
		height: 24px;
		vertical-align: middle;
		width: 24px;
	}
}

.masthead__wrap {
	align-items: flex-end;
	display: flex;
	margin: 0 auto;
	max-width: 85vw;
	width: 100%;
}

a.masthead__logo-link {
	display: flex;
	margin-bottom: 10px;
	transition: 0;
	@media only screen and (min-width: base.$breakpoint-m) {
		margin-bottom: 20px;
	}

	&:hover,
	&:focus {
		background-color: var(--color-masthead-bg--active);
		outline: 10px solid var(--color-masthead-bg--active);
		outline-offset: 0;
	}
}

.masthead__logo {
	background-color: transparent;
	display: flex;
	max-height: 100%;
}

.masthead__content {
	background-color: transparent;
	color: var(--color-masthead-text);
	height: 100%;
	width: 100%;
}

.masthead__top-bar {
	align-items: center;
	display: flex;
	font-size: 14px;
	height: var(--masthead-height);
	justify-content: flex-end;

	@media only screen and (min-width: base.$breakpoint-m) {
		align-items: flex-start;
		height: $masthead-top-bar-height;
	}
}

.masthead__top-right {
	display: inline-flex;
	@media only screen and (min-width: base.$breakpoint-m) {
		display: none;
	}
}

.masthead__bottom-bar {
	align-items: center;
	display: flex;
	height: 0;
	margin: 0;
	overflow: hidden;
	z-index: base.z('positive-default');

	@media only screen and (min-width: base.$breakpoint-m) {
		height: $masthead-bottom-bar-height;
		overflow: visible;
	}

	&.is-open {
		display: block;
		height: auto;
		left: 0;
		overflow-y: auto;
		position: absolute;
		right: 0;
		top: var(--masthead-height);
		width: 100%;

		@media only screen and (min-width: base.$breakpoint-s) {
			left: auto;
			width: calc(15vw + 240px);
		}
		@media only screen and (min-width: base.$breakpoint-m) {
			height: $masthead-bottom-bar-height;
			overflow: visible;
			position: static;
			width: 100%;
		}
	}
}

.masthead__eyebrow-links {
	margin-right: 20px;

	@media only screen and (min-width: base.$breakpoint-s) {
		text-transform: capitalize;
	}

	@media only screen and (min-width: base.$breakpoint-m) {
		margin-right: 0;
		text-transform: none;
	}

	a {
		font-size: 14px;
		padding: 5px 10px;
		@media only screen and (min-width: base.$breakpoint-s) {
			padding: 5px 10px;
		}
		@media only screen and (min-width: base.$breakpoint-m) {
			font-size: 12px;
		}

		&:last-child {
			padding-right: 0;
		}
	}
}

.masthead__buy-tickets {
	display: inline-block;

	&::before {
		content: 'Buy ';
	}
	@media only screen and (min-width: base.$breakpoint-s) {
		&::before {
			content: '';
		}
	}
	@media only screen and (min-width: base.$breakpoint-m) {
		background-color: var(--color-masthead-subitem-bg);
		transition: all 0.1s;

		&::before {
			content: 'Buy ';
		}

		&:focus,
		&:hover {
			background-color: var(--color-masthead-bg--active);
			text-decoration: none;
		}
	}
}


.masthead__membership {
	display: none;

	@media only screen and (min-width: base.$breakpoint-s) {
		display: inline-block;

		&::after {
			content: 'ship';
		}
	}

	@media only screen and (min-width: base.$breakpoint-m) {
		&::before {
			content: 'Become a ';
		}

		&::after {
			content: '';
		}
	}
}

.masthead__donation {
	display: none;

	@media only screen and (min-width: base.$breakpoint-m) {
		display: inline-block;
	}
}

.masthead__search {
	align-items: center;
	border-color: transparent;
	display: flex;
	text-transform: uppercase;

	&:hover,
	&:focus {
		outline-offset: 0;
	}

	.icon {
		height: 20px;
		margin-right: 4px;
		width: 20px;
		@media only screen and (min-width: base.$breakpoint-m) {
			position: relative;
			transform: rotateY(180);
			width: 16px;
		}
	}

	&.masthead__search--small {
		display: inline-block;
		@media only screen and (min-width: base.$breakpoint-m) {
			display: none;
		}
	}
}

.masthead__search-label {
	display: none;
	text-transform: none;
	@media only screen and (min-width: base.$breakpoint-m) {
		display: inline-block;
	}
}

a.masthead-button {
	&:hover {
		text-decoration: underline;
	}

	&:focus {
		color: var(--color-grey-900);
	}

}

a.masthead__hamburger {
	margin-left: 1em;
	@media only screen and (min-width: base.$breakpoint-m) {
		display: none;
	}

	.icon {
		width: 20px;
	}

	.masthead-icon--hamburger {
		display: none;
	}

	.masthead-icon--close {
		display: inline-block;
	}

	&.show-open {
		.masthead-icon--hamburger {
			display: inline-block;
		}

		.masthead-icon--close {
			display: none;
		}
	}
}
