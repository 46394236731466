@use 'global-styles/marble/base/base.scss';

:root {
	--textsize-xxs: #{base.$textsize-xxs};
	--textsize-xs: #{base.$textsize-xs};
	--textsize-s: #{base.$textsize-s};
	--textsize-m: #{base.$textsize-m};
	--textsize-l: #{base.$textsize-l};
	--textsize-xl: #{base.$textsize-xl};
	--textsize-xxl: #{base.$textsize-xxl};
	--textsize-xxxl: #{base.$textsize-xxxl};
}
