body {
	color: var(--color-text);
	font-size: var(--textsize-s);
	font-weight: 400;
	line-height: 1.75;
}

p {
	font-family: inherit;
	max-width: var(--line-length-max);
}
