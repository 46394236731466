@use 'global-styles/marble/base/base.scss';
@forward 'nav-variables';
@forward 'masthead';
@forward 'nav-main';

.masthead {
	z-index: base.z('global-nav');

	a:focus-visible {
		background-color: var(--color-masthead-bg--active);
		color: var(--color-masthead-text);
		outline: 4px solid var(--color-masthead-bg--active);
	}
}
