@use 'global-styles/marble/base/base.scss';

.button,
button {
	align-items: center;
	background: transparent;
	border: 0;
	border-radius: 4px;
	color: currentcolor;
	display: inline-flex;
	font-family: var(--sans-serif);
	font-size: 1em;
	font-weight: 500;
	justify-content: center;
	padding: 0;

	&:not(:disabled):hover {
		cursor: pointer;
	}
	
	&:disabled,
	&:disabled:focus,
	&:disabled:hover {
		background: var(--color-component-background);
		box-shadow: none;
		color: var(--color-text);
	}

	&:focus-visible {
		outline: 1.5px solid var(--color-link);
		outline-offset: 1.5px;
	}
}

.primary,
.secondary {
	height: 48px;
	min-width: 160px; //We might want to make this a CSS var but I'm not sure yet.
	padding: 0.5em 0.75em;
	text-decoration: none;
}

.primary {
	background:linear-gradient(to right,
	var(--color-met-red),
	var(--color-met-red-medium),
	var(--color-met-red-dark));
	background-position: 0 0;
	background-size: 200% 200%;
	color: var(--color-white);
	transition: background-position 0.3s, background-size 0.3s;

	&:focus,
	&:hover {
		background-position: 100% 0;
		color: var(--color-white);
	}
}

.secondary {
	box-shadow: inset 0 0 0 2px var(--color-link);
	color: var(--color-link);

	&:focus,
	&:hover {
		background-color: var(--color-link);
		color: var(--color-site-background);
	}
}

.button.primary {
	color: var(--color-white);
}

.tertiary {
	align-items: center;
	border: 1px solid var(--color-border);
	color: var(--color-link);
	display: inline-flex;
	font-size: var(--textsize-xs);
	gap: 4px;
	justify-content: center;
	padding: 0.5em 0.75em;
	text-decoration: none;
	width: auto;

	&:focus,
	&:hover {
		border-color: var(--color-border-active);
	}
}
