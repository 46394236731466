:root {
	--color-site-background: var(--color-white);

	--color-link: var(--color-grey-900);
	--color-text-header: var(--color-grey-900);

	--color-text: var(--color-grey-700);

	--color-component-background: var(--color-grey-100);
	--color-component-background-2: var(--color-white);

	--color-divider: var(--color-grey-200);
	--color-border: var(--color-grey-400);
	--color-border-active: var(--color-grey-700);

	--color-modal-backdrop: var(--color-black-transparency);

	//States
	--color-focus-state-outline: var(--color-blue-100);
	--color-focus-state-background: var(--color-blue-600);

	--color-active-tab: var(--color-met-red-medium);

	@media (prefers-contrast: more) {
		--color-text: var(--color-grey-900);
	}

	//Commented out for now but keeping here for an easy "go
	// @media (prefers-color-scheme: dark) {
	// 	--color-black: #040404;
	// 	--color-site-background: var(--color-grey-900);

	// 	--color-link: var(--color-white);
	// 	--color-text-header: var(--color-white);
	// 	--color-text: var(--color-grey-400);

	// 	--color-component-background: var(--color-black);
	// 	--color-component-background-2: var(--color-grey-900);

	// 	//TODO handle nav better when we really dive into that.
	// 	--color-masthead-bg: var(--color-met-red-medium);
	// 	--color-masthead-bg--active: rgba(255, 255, 255, 0.4);
	// 	--color-masthead-subitem-bg: var(--color-met-red-medium);
	// 	--color-modal-backdrop: rgba(0,0,0,0.3);
	// }

	@media (prefers-color-scheme: dark) and (prefers-contrast: more) {
		--color-text: var(--color-white);
		--color-text: var(--color-white);
	}
}

// img { ///TODO What do we do witht this? It's weird but it looks okay?
// 	@media (prefers-color-scheme: dark) {
// 		background-color: var(--color-white);
// 	}
// }
