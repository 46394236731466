/* stylelint-disable max-nesting-depth */
@use 'global-styles/marble/base/base.scss';

$focus-color: #5e9ed6;

.nav-main {
	background-color: inherit;
	color: var(--color-masthead-text);
	width: 100%;

	@media only screen and (min-width: base.$breakpoint-m) {
		height: 100%;
	}

	> ul {
		border: 0;
		list-style-type: none;
		margin: 0;
		padding: 0;
	}

	a {
		text-decoration: none;
	}
}

.nav-main__primary {
	background-color: var(--color-masthead-bg);
	border-top: 1px solid var(--color-masthead-text);
	display: block;
	text-align: left;
	z-index: base.z('positive-default');
	@media only screen and (min-width: base.$breakpoint-m) {
		align-items: flex-end;
		background-color: inherit;
		border-top: 0;
		display: flex;
		flex-wrap: nowrap;
		height: 100%;
		padding: 0;
	}
}

.nav-main__primary-item,
.nav-main__subnav {
	background-color: inherit;
	background-size: 200vw;
	line-height: 1;
	margin-bottom: 0;
	@media only screen and (min-width: base.$breakpoint-m) {
		background-image: none;
		line-height: 1.6;

		&.is-active {
			border-bottom: 0;
		}
	}
}

.nav-main__primary-item {
	display: flex;
	flex-direction: column;
	font-size: var(--textsize-s);
	width: 100%;
	z-index: base.z('positive-default');

	&:last-child {
		border-bottom: 0;
	}

	@media only screen and (min-width: base.$breakpoint-m) {
		border: 0;
		padding: 0 20px 10px;
		position: relative;
		white-space: nowrap;
		width: auto;
	}
}

li:hover .nav-main__link--parent,
li:focus-within .nav-main__link--parent {
	border-bottom: 2px solid transparent;
	@media only screen and (min-width: base.$breakpoint-m) {
		border-bottom: 2px solid var(--color-masthead-text);
	}
}

.nav-main__link {
	border-bottom: 2px solid transparent;
	color: var(--color-masthead-text);
	display: block;
	font-weight: 500;

	.nav-main__external-icon {
		display: none;

		svg {
			height: 0.6em;
			width: 0.6em;
		}
	}

	&.nav-main__link--parent {
		&.is-active,
		&.is-section {
			@media only screen and (min-width: base.$breakpoint-m) {
				border-bottom: 2px solid var(--color-masthead-text);
			}
		}
	}

	&.nav-main__link--external {
		&.is-active,
		&.is-section,
		&:focus-within,
		&:hover {
			.nav-main__external-icon {
				@media only screen and (min-width: base.$breakpoint-m) {
					display: inline-block;
					margin-left: 5px;
					position: relative;
					top: 2px;
				}
			}
		}
	}
}

.nav-main__link--primary {
	align-items: center;
	display: flex;
	justify-content: space-between;
	padding: 16px 7.5vw;
	width: 100%;
	@media only screen and (min-width: base.$breakpoint-m) {
		padding: 0;
	}
}

.nav-main__search-button {
	border: 0;
	display: none;
	margin-left: auto;
	padding-right: 0;
	@media only screen and (min-width: base.$breakpoint-m) {
		display: block;
	}
}

.nav-main__secondary {
	background: transparent;
	background: var(--color-masthead-bg-dark);
	background-size: 200vw;
	display: block;
	list-style: none;
	margin: 0;
	min-width: 100%;
	padding-left: 0;
	position: static;
	text-align: left;
	transform: translateY(0);
	z-index: base.z('positive-default');
	@media only screen and (min-width: base.$breakpoint-m) {
		background: var(--color-masthead-bg);
		position: absolute;
	}
}

.nav-main__secondary-item {
	background-color: inherit;
	font-size: var(--textsize-s);
	margin-bottom: 0;
	margin-top: 0;

	@media only screen and (min-width: base.$breakpoint-m) {
		background-color: inherit;
		line-height: 1;
		margin-top: 1px;
	}

	&:hover,
	&:focus-within {
		background-color: var(--color-masthead-subitem-bg);
	}

	&:last-child {
		border-bottom: 0;
	}
}

.nav-main__link-sub {
	display: inline-block;
	padding: 10px 7.5vw;
	width: 100%;
	@media only screen and (min-width: base.$breakpoint-m) {
		padding: 19px 20px 13px;
	}
}

.nav-main__secondary-item:first-child .nav-main__link-sub {
	@media only screen and (min-width: base.$breakpoint-m) {
		padding-top: 20px;
	}
}

.nav-main__secondary-item:last-child .nav-main__link-sub {
	@media only screen and (min-width: base.$breakpoint-m) {
		padding-bottom: 15px;
	}
}

.nav-main__subnav {
	clear: both;
	display: none;
	width: 100%;
	@media only screen and (min-width: base.$breakpoint-m) {
		bottom: 0;
		left: 0;
		position: absolute;
		width: 0;

		&.is-open {
			display: none;
		}
	}
}

.is-active {
	.nav-main__subnav {
		display: block;
		@media only screen and (min-width: base.$breakpoint-m) {
			display: none;
		}
	}
}

.nav-main__primary-item:focus-within,
.nav-main__primary-item:hover {
	.nav-main__subnav {
		@media only screen and (min-width: base.$breakpoint-m) {
			display: inline-block;
			min-width: 100%;

			&.is-open {
				display: inline-block;
			}
		}
	}
}

.nav-main__primary-item:focus-within {
	background-color: rgba(var(--color-grey-900), 0.4);

	@media only screen and (min-width: base.$breakpoint-m) {
		background-color: transparent;
	}
}

.nav-main__subnav--search {
	display: block;
	height: 0;
	top: 20px;
	width: 100%;

	@media only screen and (min-width: base.$breakpoint-m) {
		position: absolute;
	}


	.nav-main__secondary--search {
		left: 0;
		padding-top: 0;
		position: absolute;
		width: 100%;
	}
}


.header__toggle-indicator {
	height: 1em;
	pointer-events: none;
	position: relative;
	width: 1em;

	&::after,
	&::before {
		content: '';
		display: inline-block;
		height: 100%;
		position: absolute;
		width: 100%;
	}

	@media only screen and (min-width: base.$breakpoint-m) {
		display: none;
	}
}

.nav-main__link--parent .header__toggle-indicator {
	&::after,
	&::before {
		background-color: var(--color-masthead-text);
		top: 50%;
	}

	&::after {
		height: 2px;
		left: 0;
		transform: translateY(-50%);
	}

	&::before {
		left: 50%;
		transform: translateX(-50%) translateY(-50%);
		transform-origin: center;
		transition: transform 0.3s;
		width: 2px;
	}
}

.is-active .nav-main__link--parent .header__toggle-indicator::before {
	transform: translateX(-50%) translateY(-50%) rotateZ(90deg);
}

.nav-main__link--external .header__toggle-indicator {
	&::before {
		border: solid var(--color-masthead-text);
		border-width: 0 2px 2px 0;
		height: 80%;
		transform: rotate(-45deg);
		width: 80%;
	}
}

.quick-search {
	margin-bottom: 0;
	max-width: none;
}

.nav-main__spinner {
	animation: loadingIconSpin 3s infinite;
	border: 2px solid var(--color-grey-700);
	border-radius: 50%;
	display: inline-flex;
	height: 25px;
	justify-content: center;
	position: relative;
	width: 25px;

	&::after {
		background: var(--color-white);
		content: '';
		display: inline-block;
		height: 10px;
		position: relative;
		top: 16px;
		transform: rotate(45deg);
		width: 10px;
	}
}

@keyframes loadingIconSpin {
	0% {
		transform: rotate(0deg);
	}

	50% {
		transform: rotate(180deg);
	}

	100% {
		transform: rotate(360deg);
	}
}
