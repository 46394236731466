@forward './typography/headings';
@forward './typography/body';

a {
	color: inherit;
	text-decoration: underline;

	//For links without an href - don't make them look or act like real links.
	&[href=''],
	&[href='']:focus,
	&[href='']:hover {
		cursor: default;
		pointer-events: none;
		text-decoration: none;
	}

	&:hover,
	&:visited {
		color: inherit;
	}

	&:focus {
		outline: none;
		text-decoration: none;
	}

	@media screen and (hover: hover) {
		&:hover {
			text-decoration: none;
		}
	}
}

b,
strong {
	color: var(--color-text-header);
	font-weight: 600;
}

em,
i {
	font-style: italic;
}


h1,
h2,
h3,
h4,
h5,
h6,
[role='heading'] {
	a {
		text-decoration: none;
		text-decoration-color: currentColor;

		@media screen and (hover: hover) {
			&:hover {
				text-decoration: underline;
				text-decoration-color: currentColor;
			}
		}
	}

	a:focus {
		outline: none;
		text-decoration: underline;
		text-decoration-color: currentColor;
	}
}


// Visually remove these empty typography tags:
h1,
h2,
h3,
h4,
h5,
h6,
p {
	&:empty {
		display: none;
		height: 0;
		margin: 0;
		padding: 0;
	}
}

li::marker {
	color: var(--color-text-header);
}
