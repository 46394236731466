/* -	Navigation - */
@use 'components/global/global-navigation/global-navigation';

/* CSS variables */
@forward './variables/sizes.scss';
@forward './variables/textsizes.scss';
@forward './variables/colors.scss';

@forward './themes';
@forward './buttons';

@forward './marble/base/base';
@forward './marble/global/global';

body {
	--sans-serif: var(--inter),
		var(--noto-sans-cy),
		var(--noto-sans-jp),
		var(--noto-sans-kr),
		var(--noto-sans-sc);
}

//TODO - Figure out if this is a real pattern and if so, where does it go.
.helper-text {
	color: var(--color-grey-700);
	font-size: var(--textsize-xs);
}

//https://developer.mozilla.org/en-US/docs/Web/CSS/env
//Lets use some ENV variables for full screen experiences ^ TODO - welcome page?
