@use 'global-styles/marble/base/base.scss';

.bar {
	backdrop-filter: blur(2px);
	background: rgba(255, 255, 255, 0.9);
	border: 2px solid var(--color-purple);
	border-radius: base.$radius-sharp;
	bottom: 0;
	color: var(--color-grey-900);
	font-size: var(--textsize-xs);
	font-weight: 600;
	left: 0;
	margin: 1em;
	padding: 8px 16px;
	position: fixed;
	z-index: base.z('preview-bar');
}

.heading {
	align-items: flex-start;
	display: flex;
	justify-content: space-between;
}

.exitLink {
	align-items: center;
	display: flex;
	gap: 0.5em;
	justify-content: flex-start;
}

.shareURLContainer {
	align-items: center;
	display: flex;
	font-size: var(--textsize-xxs);
	font-weight: 500;
	gap: var(--spacing-micro);
	margin-top: var(--spacing-micro);
}

.previewShareURL {
	background: var(--color-grey-100);
	display: inline-block;
	font-family: monospace;
	font-size: 12px;
	max-width: 24ch;
	overflow: scroll;
}

.message {
	font-size: var(--textsize-xxs);
	font-weight: 500;
}
