@use 'global-styles/marble/base/base.scss';

:root {
	--color-masthead-bg--active: rgba(0, 0, 0, 0.4);
	--color-masthead-subitem-bg: rgba(0, 0, 0, 0.2);
	--color-masthead-bg: #e4002b;
	--color-masthead-bg-dark: var(--color-met-red-medium);
	--color-masthead-text: #fff;
	--masthead-height: 60px;
	@media only screen and (min-width: base.$breakpoint-m) {
		--masthead-height: 80px;
	}
}
