//Spacing and Widths
:root {
	--spacing-micro: calc(8px + 0.25vw);
	--spacing-xxs: calc(16px + 0.5vw);
	--spacing-xs: calc(24px + 0.75vw);
	--spacing-s: calc(32px + 1vw);
	--spacing-m: calc(48px + 1.5vw);
	--spacing-l: calc(64px + 2vw);
	--spacing-xl: calc(98px + 6vw);
	--spacing-xxl: calc(144px + 9vw);
	--max-width-unclamped: 85vw;
	//absolute, hard max width for productive component
	--max-component-size: 1600px;
	//a width that is comfortable to read at the default body font size
	--comfortable-reading-width-max: calc(625px + 1.2vw);
	//Account for scrollbar width on windows
	--width--scrollbar-agnostic: calc(var(--max-width-unclamped) - (100vw - 100%));
	//Clamped width. max-width-unclamped up to a max of max-component-size
	--productive-width-max: min(var(--width--scrollbar-agnostic),
	var(--max-component-size));
	//Half of the difference between 100vw and productive-width-max
	--spacing-component-margin: calc((100% - var(--productive-width-max)) / 2);

	--spacing-paragraph: 1.5em;
	
	//Ideal reading lengths.
	--line-length-max: 60em;
	--line-length-comfortable: 37em;
}
