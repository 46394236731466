@use 'global-styles/marble/base/base.scss';

h1,
h2,
h3,
h4,
h5,
h6 {
	color: var(--color-text-header);
	font-family: inherit;
}

h1 {
	@include base.typography-h1;
}

h2 {
	@include base.typography-h2;
}

h3 {
	@include base.typography-h3;
}

h4 {
	@include base.typography-h4;
}

h5 {
	@include base.typography-h5;
}

.serif {
	font-family: var(--austin);
	font-weight: 500;
}
